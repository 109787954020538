import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { AemComponentEntity, ProductEntity } from 'constants/data-shapes/product-entity';

import ProductGroup from 'components/ProductGrid/Group';
import ProductList from 'components/ProductList';
import ProductGridItems from 'components/ProductGrid/ProductGridItems';
import ViewAlternativesProductModal from 'components/ProductGrid/ViewAlternativesProductModal';
import Spinner from '@johnlewispartnership/wtr-ingredients/ingredients/Spinner';
import { useLoading } from './Hooks/use-loading';
import styles from './ProductGrid.scss';

const ProductGrid = ({
  Empty,
  groups,
  InfiniteScroll,
  isBuilderPage,
  listId,
  loading,
  LoadMoreButton,
  noModals,
  onDataLoad,
  orderId,
  products,
  resetError,
  searchType,
  taxonomyLevel,
}) => {
  useEffect(() => {
    // Hide the error of a failed search when loading the view
    // example going from search to login and back
    resetError();
  }, [resetError]);

  const { showSpinner } = useLoading({ orderId, loading, onDataLoad });

  const hasProducts = useMemo(() => products.length > 0, [products.length]);

  const contents = useMemo(() => {
    if (showSpinner) {
      return <Spinner className="spinnerWrapper" isActive pageSpinner />;
    }

    if (!loading && !hasProducts) {
      return Empty ? <Empty /> : null;
    }

    const displayInGroups = groups && groups.size > 0;

    const contextProducts = groups
      ? [...groups.values()]
          .flat()
          .map(
            flattenProduct =>
              products.filter(
                product => product.searchProduct === flattenProduct.searchProduct,
              )[0] || {},
          )
      : null;

    const productListBody = displayInGroups ? (
      <div className="container-fluid">
        <ul className={styles.productGroups}>
          {[...groups.keys()].map(categoryId => (
            <li aria-label="Grouped Products" className={styles.productGroup} key={categoryId}>
              <ProductGroup
                categoryId={categoryId}
                listId={listId}
                isBuilderPage={isBuilderPage}
                orderId={orderId}
                products={groups.get(categoryId)}
                contextProducts={contextProducts}
                searchType={searchType}
                taxonomyLevel={taxonomyLevel}
                key={categoryId}
              />
            </li>
          ))}
        </ul>
      </div>
    ) : (
      <ProductGridItems
        isBuilderPage={isBuilderPage}
        items={products}
        listId={listId}
        searchType={searchType}
        taxonomyLevel={taxonomyLevel}
      />
    );

    return (
      <>
        <ProductList
          {...{
            withContainer: !displayInGroups,
            displayInGroups,
            InfiniteScroll,
            loading,
            LoadMoreButton,
            withListModal: !noModals,
          }}
        >
          {productListBody}
        </ProductList>
      </>
    );
  }, [
    Empty,
    InfiniteScroll,
    isBuilderPage,
    LoadMoreButton,
    groups,
    hasProducts,
    listId,
    loading,
    noModals,
    orderId,
    products,
    searchType,
    showSpinner,
    taxonomyLevel,
  ]);

  return (
    <>
      <div className={styles.products} data-testid="product-grid">
        {contents}
      </div>
      <ViewAlternativesProductModal />
    </>
  );
};

ProductGrid.propTypes = {
  Empty: PropTypes.elementType,
  groups: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.bool]),
  InfiniteScroll: PropTypes.elementType,
  isBuilderPage: PropTypes.bool,
  listId: PropTypes.string,
  loading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  LoadMoreButton: PropTypes.object,
  orderId: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.oneOfType([AemComponentEntity(), ProductEntity()])),
  resetError: PropTypes.func.isRequired,
  searchType: PropTypes.string,
  taxonomyLevel: PropTypes.string,
  onDataLoad: PropTypes.func,
  noModals: PropTypes.bool,
};

ProductGrid.defaultProps = {
  Empty: () => null,
  groups: undefined,
  InfiniteScroll: null,
  isBuilderPage: false,
  listId: undefined,
  loading: true,
  LoadMoreButton: null,
  orderId: null,
  products: [],
  searchType: null,
  taxonomyLevel: null,
  onDataLoad: undefined,
  noModals: false,
};

export default ProductGrid;
